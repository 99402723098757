import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const OffsetPageContext = createContext();

const initialState = 1;

const OffsetPageProvider = ({ children }) => {
  const [offset, setOffset] = useState(initialState);

  return (
    <OffsetPageContext.Provider value={[offset, setOffset]}>{children}</OffsetPageContext.Provider>
  );
};

OffsetPageProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { OffsetPageProvider, OffsetPageContext };
