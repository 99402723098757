import { createMuiTheme } from '@material-ui/core/styles'
import ButtonOverride from '../overrides/Button'
import AutocompleteOverride from '../overrides/Autocomplete'
import baseTheme from './base'
import palette from '../palette'
import colors from '../colors'
import { NewTypographyOverride as TypographyOverride } from '../overrides/Typography'

const newBaseTheme = createMuiTheme({
  ...baseTheme,
  palette: {
    ...palette,
    background: {
      default: colors.white,
    },
  },
  MuiGrid: { spacing: 0 },
})

newBaseTheme.overrides = {
  ...baseTheme.overrides,
  MuiButton: ButtonOverride(baseTheme),
  MuiAutocomplete: AutocompleteOverride(baseTheme),
  MuiTypography: TypographyOverride(baseTheme),
}

export default newBaseTheme
