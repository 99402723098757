export default {
  NONE: 0,
  PENDING: 1,
  APPROVED: 2,
  REPROVED: 3,
  CANCELED: 4,
  EXPIRED: 5,
  REPROVED_BY_PAGARME: 6,
  RATING: 'rating',
  REPROVED_BY_ADMIN: 10
};
