const mode = process.env.NEXT_PUBLIC_MODE_SERVER;
const debugMode = process.env.NODE_ENV === 'development';

const isProduction = mode === 'PRODUCTION';

const GA_TRACKING_ID = isProduction ? 'UA-162608775-1' : 'UA-205712426-1';
const GA_ADS_ID = 'AW-654946432';
const FB_PIXEL_CODE = '509716839667020';

const appConfig = {
  debugMode,
  mode,
  devToolsEnabled: debugMode,
  GA_TRACKING_ID,
  GA_ADS_ID,
  FB_PIXEL_CODE,
  whereByApiToken:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmFwcGVhci5pbiIsImF1ZCI6Imh0dHBzOi8vYXBpLmFwcGVhci5pbi92MSIsImV4cCI6OTAwNzE5OTI1NDc0MDk5MSwiaWF0IjoxNjIzMTU1ODYyLCJvcmdhbml6YXRpb25JZCI6MTExNzA3LCJqdGkiOiI4ZWJiMWU4ZS03ZjVjLTQxYzUtYjMwYS0yMWFlYWU5OGQzNmEifQ.xdFwZKy8n0kLreN1w6Nt0ybrc-PjbioRES1QT8JBkIA',
};

// @todo: remover essa constante daqui: Esse tipo de informação deveria vir de um serviço
export const lawsAndRatesTermId = '0951ad97-4949-4428-bdc0-800fc230f518';
export const termsOfPaymentId = '9c8cf829-b329-490b-af43-d91b7dce7283';
export const termsOfServiceId = '4deb95eb-3ef3-4181-b162-65b39d0c796c';
export const termsOfPrivacyPolicyId = 'c162778e-72bc-4207-bbd1-d53d52316fb3';

export { isProduction };
export default appConfig;
