import { newColors } from '../colors'

export default {
  primary: {
    blue: newColors.blue_003,
    green: newColors.green_34b,
    graphite: newColors.grey_38,
  },
  secondary: {
    darkBlue: newColors.blue_000,
    lightBlue: newColors.blue_37c,
    lightGreen: newColors.green_3fe,
    veryLightGreen: newColors.green_f0f,
    mediumBlue: newColors.blue_0c7,
    orange: newColors.orange_fa6,
    red: newColors.red_e82,
    yellow: newColors.yellow_f0b,
    darkGrey: newColors.grey_79,
    grey: newColors.grey_a6,
    lightGrey: newColors.grey_e6,
    veryLightGrey: newColors.grey_f6,
    white: newColors.white,
  },
  text: {
    graphite: newColors.grey_38,
    darkGrey: newColors.grey_79,
    grey: newColors.grey_a6,
    error: newColors.red_cb1,
  },
  feedback: {
    success: newColors.green_cee,
    error: newColors.red_eda,
    info: newColors.blue_d5e,
  },
}
