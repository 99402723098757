const colors = {}

// Background colors
colors.white = '#FFFFFF'
colors.gray = `#E6E6E6`
colors.grayA6 = `#A6A6A6`
colors.gray79 = `#797979`
colors.grayF5 = `#F5F5F5`
colors.backgroundWarning = `#FA683B`
colors.backgroundInfo = `#CFEFFB`

// Graphite colors
colors.graphite = '#383838'
colors.graphite60 = 'hsla(0, 0%, 14%, 0.6)'
colors.graphite80 = 'hsla0, 0%, 14%, 0.8)'
colors.lightGraphite = 'hsla(0, 0%, 47%, 1)'

// Blue colors
const denim = '212, 90%, 48%'
colors.lightBlue = `#0031FF`
colors.darkBlue = `#0028D1`
colors.darkBlue15 = `rgba(0, 40, 209, 0.15)`
colors.darkBlue45 = `rgba(0, 40, 209, 0.45)`
colors.darkBlue50 = `rgba(0, 40, 209, 0.50)`
colors.cyan = `hsla(195, 83%, 57%, 1)`
colors.denim = `hsla(${denim}, 1)`
colors.denim15 = `hsla(${denim}, 0.15)`

// Green colors
colors.aqua = `hsla(162, 38.5%, 95%, 1)`
colors.lightGreen = `#34BA92`
colors.lightGreen15 = `rgb(52, 186, 146, 0.15)`
colors.darkGreen = `#299474`
colors.darkGreen15 = `rgba(52, 186, 146, 0.15)`
colors.darkGreen45 = `rgba(52, 186, 146, 0.45)`
colors.darkGreen50 = `rgba(52, 186, 146, 0.50)`

// feedback
colors.success = `rgba(53, 187, 146, .30)`
colors.error = 'rgba(232, 42, 22, 0.45)'
colors.warning = 'rgba(250, 104, 59, .20)'
colors.info = `rgba(62, 190, 237, .25)`
colors.icon = {
  warning: '#FA683B',
  error: '#E82A16',
  success: '#36BC92',
  info: '#37C0ED',
}

// login buttons
colors.border = 'hsl(0, 0%, 65.1%)'
colors.borderHover = 'hsl(0, 0%, 21%)'

export default Object.freeze(colors)
