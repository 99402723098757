import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import appConfig from './index';

const sentryConfig = {
  init: () => {
    Sentry.init({
      dsn: 'https://bcaf40dcea3e49d5b93d9099d446b0d5@o377879.ingest.sentry.io/5201402',
      environment: appConfig.mode,
      release: process.env.SOURCE_VERSION,
      integrations: [new Integrations.BrowserTracing()],
      attachStacktrace: true,
      tracesSampleRate: 1.0,
    });
  },
  setUser: email => {
    if (email) {
      Sentry.setUser({ email });
    } else {
      Sentry.setUser({ username: 'unlogged' });
    }
  },
  removeUser: () => {
    Sentry.configureScope(scope => scope.setUser(null));
  },
};

export default sentryConfig;
