import palette from '../../palette'
import sizes from '../../sizes'

const TypographyOverride = theme => ({
  h1: {
    color: palette.text.primary,
    fontSize: theme.typography.pxToRem(sizes.font.biggest),
    fontWeight: 600,
    lineHeight: '63px',
  },
  h2: {
    color: palette.text.primary,
    fontSize: theme.typography.pxToRem(sizes.font.big),
    fontWeight: 600,
    lineHeight: '52px',
  },
  h3: {
    fontSize: theme.typography.pxToRem(sizes.font.largest),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.medium),
    },
    color: palette.text.primary,
    fontWeight: 600,
    lineHeight: '44px',
  },
  h4: {
    fontSize: theme.typography.pxToRem(sizes.font.large),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.small),
    },
    color: palette.text.primary,
    fontWeight: 600,
    lineHeight: '38px',
  },
  h5: {
    fontSize: theme.typography.pxToRem(sizes.font.medium),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.normal),
    },
    color: palette.text.primary,
    fontWeight: 600,
    lineHeight: '26px',
  },
  h6: {
    fontSize: theme.typography.pxToRem(sizes.font.small),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.smallest),
    },
    color: palette.text.primary,
    fontWeight: 600,
    lineHeight: '34px',
  },
  subtitle1: {
    fontSize: theme.typography.pxToRem(sizes.font.smallest),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.little),
    },
    color: palette.text.primary,
    fontWeight: 600,
    lineHeight: '34px',
  },
  subtitle2: {
    fontSize: theme.typography.pxToRem(sizes.font.small),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.little),
    },
    color: palette.text.primary,
    lineHeight: '26px',
    fontWeight: 400,
  },
  body1: {
    color: palette.text.primary,
    fontSize: theme.typography.pxToRem(sizes.font.normal),
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.little),
      lineHeight: '18px',
    },
  },
  body2: {
    color: palette.text.secondary,
    fontSize: theme.typography.pxToRem(sizes.font.smallest),
    lineHeight: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(sizes.font.little),
    },
  },
  button: {
    color: palette.text.primary,
    fontSize: theme.typography.pxToRem(sizes.font.little),
    textTransform: 'none',
  },
  caption: {
    color: palette.text.primary,
    fontSize: theme.typography.pxToRem(sizes.font.tiny),
    lineHeight: '18px',
  },
  overline: {
    color: palette.text.primary,
    fontSize: theme.typography.pxToRem(sizes.font.tiny),
    fontWeight: 600,
    lineHeight: '16px',
  },
  colorTextSecondary: {
    color: palette.text.secondary,
  },
})

export default TypographyOverride
