import colors from "../../colors";

const AutocompleteOverride = (theme) => {
  return {
    root: {
      margin: theme.spacing(2, 0, 0, 0),
      [theme.breakpoints.down("xs")]: {
        "& .MuiAutocomplete-input": {
          // fontSize: '12px',
          // https://codepen.io/jakob-e/pen/yakBwJ
          transformOrigin: "top left",
          transform: "scale(0.75)", //  12px / 16px
          //  size up
          // width: '240px', // 180px / 0.75
          height: "32px", //  24px / 0.75
          padding: "5.333333px", //   4px / 0.75
          // borderRadius: '6.666667px', //   5px / 0.75
          // borderWidth: '1.333333px', //   1px / 0.75

          //  size down
          marginRight: "-60px", // 240px * 0.25
          marginBottom: "-8px", //  32px * 0.25
        },
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
      },
    },
    popper: {
      marginTop: "-7px",
      zIndex: 99999,
    },
    noOptions: { display: 'none' },
    listbox: {
      backgroundColor: colors.grayF5,
    },
    option: {
      color: "hsla(0, 0%, 47%, 1)",
      backgroundColor: colors.grayF5,
      fontStyle: "italic",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
      "&:hover": {
        backgroundColor: "hsla(195, 83%, 57%, 0.2)",
      },
      justifyContent: "space-between",
    },
    tag: {
      backgroundColor: "hsla(195, 83%, 57%, 0.2)",
      "& svg": {
        width: "12px",
        height: "12px",
        color: "#37C0ED",
      },
    },
  };
};

export default AutocompleteOverride;
