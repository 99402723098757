import font from '../../font'

const TypographyOverride = ({ breakpoints, typography }) => ({
  h1: {
    fontSize: typography.pxToRem(font.size.font20),
    lineHeight: typography.pxToRem(font.size.font24),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font26),
      lineHeight: typography.pxToRem(font.size.font32),
    },
  },
  h2: {
    fontSize: typography.pxToRem(font.size.font18),
    lineHeight: typography.pxToRem(font.size.font24),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font24),
      lineHeight: typography.pxToRem(font.size.font30),
    },
  },
  h3: {
    fontSize: typography.pxToRem(font.size.font16),
    lineHeight: typography.pxToRem(font.size.font22),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font22),
      lineHeight: typography.pxToRem(font.size.font28),
    },
  },
  h4: {
    fontSize: typography.pxToRem(font.size.font14),
    lineHeight: typography.pxToRem(font.size.font20),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font20),
      lineHeight: typography.pxToRem(font.size.font26),
    },
  },
  h5: {
    fontSize: typography.pxToRem(font.size.font12),
    lineHeight: typography.pxToRem(font.size.font16),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font18),
      lineHeight: typography.pxToRem(font.size.font24),
    },
  },
  h6: {
    fontSize: typography.pxToRem(font.size.font10),
    lineHeight: typography.pxToRem(font.size.font16),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font16),
      lineHeight: typography.pxToRem(font.size.font22),
    },
  },
  body1: {
    fontSize: typography.pxToRem(font.size.font14),
    lineHeight: typography.pxToRem(font.size.font20),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font16),
      lineHeight: typography.pxToRem(font.size.font20),
    },
  },
  body2: {
    fontSize: typography.pxToRem(font.size.font12),
    lineHeight: typography.pxToRem(font.size.font18),
    fontWeight: font.weight.regular,
    [breakpoints.up('sm')]: {
      fontSize: typography.pxToRem(font.size.font14),
      lineHeight: typography.pxToRem(font.size.font18),
    },
  },
})

export default TypographyOverride
