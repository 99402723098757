import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import EBookingSituation from 'enum/EBookingSituation';

const FilterBookingsContext = createContext();

const initialState = { id: EBookingSituation.NONE };

const FilterBookingsProvider = ({ children }) => {
  const [filter, setFilter] = useState(initialState);

  return (
    <FilterBookingsContext.Provider value={[filter, setFilter]}>
      {children}
    </FilterBookingsContext.Provider>
  );
};

FilterBookingsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { FilterBookingsProvider, FilterBookingsContext };
