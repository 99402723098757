import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CSSBaseline from '@material-ui/core/CssBaseline'
import { useTheme } from '@material-ui/core/styles'

const NewCssBaseLine = props => {
  const theme = useTheme()
  makeStyles({
    '@global': {
      body: {
        fontFamily: theme.typography.fontFamily,
        overflowX: 'hidden',
      },
      a: {
        textDecoration: 'none',
      },
    },
  })()

  return <CSSBaseline {...props} />
}

export default NewCssBaseLine
