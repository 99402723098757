import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState({});

  return (
    <SnackbarContext.Provider value={[message, setMessage]}>{children}</SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { SnackbarProvider, SnackbarContext };
