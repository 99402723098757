import { createMuiTheme } from '@material-ui/core/styles'
import ButtonOverride from '../overrides/Button'
import AutocompleteOverride from '../overrides/Autocomplete'
import TypographyOverride from '../overrides/Typography'

const baseTheme = createMuiTheme({
  components: {
    MuiGrid: { spacing: 8 },
    MuiWithWidth: { initialWidth: 'xs' },
  },
  typography: {
    fontFamily: [
      '"Montserrat"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
  },
})

baseTheme.overrides = {
  ...baseTheme.overrides,
  MuiButton: ButtonOverride(baseTheme),
  MuiAutocomplete: AutocompleteOverride(baseTheme),
  MuiTypography: TypographyOverride(baseTheme),
}

export default baseTheme
