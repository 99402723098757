import { createMuiTheme } from '@material-ui/core/styles'
import baseTheme from './base'
import palette from '../palette'
import colors from '../colors'

const lightTheme = createMuiTheme({
  ...baseTheme,
  palette: {
    ...palette,
    background: {
      default: colors.white,
    },
  },
})

export default lightTheme
