import colors from '../../colors'

const ButtonOverride = () => ({
  root: {
    textTransform: 'initial',
    fontSize: '16px',
    height: '50px',
    padding: '0 24px 0 24px',
    boxShadow: 'none',
  },
  contained: {
    padding: '0 24px 0 24px',
    backgroundColor: colors.graphite,
    color: colors.white,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  containedPrimary: {
    '&:disabled': {
      backgroundColor: colors.darkBlue15,
      color: colors.darkBlue45,
    },
  },
  outlined: {
    borderWidth: '1px',
    padding: '0 24px 0 24px',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  outlinedPrimary: {
    borderWidth: '1px',
    color: colors.lightBlue,
    boxShadow: 'none',
    '&:hover': {
      color: colors.darkBlue,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: `2px solid ${colors.darkBlue50}`,
      color: colors.darkBlue50,
    },
  },
  containedSecondary: {
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: colors.darkGreen15,
      color: colors.darkGreen45,
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  outlinedSecondary: {
    borderWidth: '1px',
    boxShadow: 'none',
    color: colors.lightGreen,
    '&:hover': {
      boxShadow: 'none',
      color: colors.darkGreen,
    },
    '&:disabled': {
      border: `2px solid ${colors.darkGreen50}`,
      color: colors.darkGreen50,
    },
  },
  containedSizeLarge: {
    height: '60px',
    padding: '0 24px 0 24px',
  },
  outlinedSizeLarge: {
    height: '60px',
    padding: '0 24px 0 24px',
  },
  containedSizeSmall: {
    height: '40px',
    fontSize: '14px',
    padding: '0 16px 0 16px',
  },
  outlinedSizeSmall: {
    height: '40px',
    fontSize: '14px',
    padding: '0 16px 0 16px',
  },
})

export default ButtonOverride
