const font = {
  size: {
    font10: 10,
    font12: 12,
    font14: 14,
    font16: 16,
    font18: 18,
    font20: 20,
    font22: 22,
    font24: 24,
    font26: 26,
    font28: 28,
    font30: 30,
    font32: 32,
    font34: 34,
  },
  weight: {
    bold: 700,
    semibold: 600,
    regular: 400,
  },
}

export default Object.freeze(font)
