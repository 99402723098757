/* eslint-disable camelcase */
/* eslint-disable max-len */
const locale = {
  translations: {
    password: 'Senha',
    confirmPassword: 'Confirmar senha',
    whatIsLetClinic: 'O que é a Let Clinic',
    space: 'Espaço',
    saveAndExit: 'Salvar e sair',
    exit: 'Sair',
    save: 'Salvar',
    cancel: 'Cancelar',
    edit: 'Editar',
    conclude: 'Concluir',
    tryAgain: 'Tentar novamente',
    loading: 'Carregando',

    welcomeToLetClinic: 'Bem-vindo(a) à Let Clinic',
    send: 'Enviar',
    continue: 'Continuar',
    register: 'Cadastrar',

    // register flow

    country: 'País',
    pleaseSelectCountry: 'Por favor, selecione o país',

    // errors
    somethingWentWrong: 'Algo deu errado!',
    invalidCredencialsUserPassword: 'Usuário e/ou senha inválido',
    blockedAccount: 'Conta bloqueada!',

    // warnings
    requiredField: 'Obrigatório',
    requiredFields: 'Preencha os campos obrigatórios',
    youNeedToFillTheFields: 'Você precisa preencher os campos para avançar',
    pleaseSelectAnOption: 'Por favor, selecione uma opção.',
    thereWasAnErrorAPI: 'Houve um erro em nossos serviços. Por favor, tente novamente.',
    emptyEmailAddressFieldWarning: 'Por favor, preencha o campo de e-mail',
    emptyPasswordFieldWarning: 'Por favor, preencha o campo de senha',

    // login
    loginSubmit: 'Entrar',
    loginWithMessage: 'Continuar com {{loginMode}}',
    loginWithEmail: 'Entrar com o e-mail',
    loginWithFacebook: 'Entrar com o Facebook',
    loginWithGoogle: 'Entrar com o Google',
    loginWithLinkedIn: 'Entrar com o LinkedIn',
    welcomeMessage: 'Bem vindo! :)',
    welcomeMessageWithName: 'Bem vindo, {{nome}}! :)',
    loginGenericErrorMessage:
      'Não foi possível realizar login na plataforma. Por favor, tente novamente mais tarde.',
    login: 'Conecte-se',
    loginCallForAction: 'A Let Clinic aproxima você dos seus pacientes. Faça parte da nossa rede.',
    orContinueWith: 'ou',
    hasNotAnAccount: 'Você ainda não tem conta?',
    signUp: 'Cadastre-se',
    enterForContinue: 'Entre para continuar',
    emailAddress: 'Endereço de e-mail',
    rememberMe: 'Lembrar de mim',
    forgetPassword: 'Esqueci a senha',
    forgotYourPasswordQuestion: 'Esqueceu a senha?',
    continueAsUser: 'Continuar como {{name}}',
    areYouNotUser: 'Não é {{name}}?',
    useAnotherAccount: 'Use outra conta',

    // calendar
    hour: 'Hora',
    hours: 'Horas',
    locked: 'bloqueado',
    unlocked: 'desbloqueado',
    lockThisMonth: 'Bloquear esse mês',
    unlockThisMonth: 'Desbloquear esse mês',
    period: 'Período',
    block: 'Bloquear',
    unblock: 'Desbloquear',
    calendarLegend: 'Neste dia todos os períodos estão disponíveis para reserva.',
    calendarLegendHours: 'Neste dia todos os horários estão disponíveis para reserva.',
    calendarLegendPartial: 'Neste dia 1 ou 2 períodos estão disponíveis para reserva.',
    calendarLegendPartialHours: 'Neste dia alguns horários estão disponíveis para reserva.',
    repeatUnlockInTheFuture: 'Repetir desbloqueio no futuro?',
    repeateWeeklyOfThisMonth: 'Semanal: todo {{weekDay}} deste mês',
    repeateDailyOfThisMonth: 'Diário: todos os dias deste mês',
    // stage1
    step01: 'Etapa 01',
    whereIsYourPlace: 'Onde fica o seu lugar?',

    // step02
    placeExactAddressExplanation:
      'O endereço exato do seu espaço só será disponibilizado depois que a reserva for feita.',
    usePreviousAddress: 'Utilizar endereço já cadastrado',

    // stage2
    step02: 'Etapa 02',
    pleaseAddAValue: 'Por favor, insira um valor.',
    charactersCountMinAlert: 'Mínimo de {{number}} caracteres',

    // step02

    errorSavingResume: 'Não foi possível salvar a descrição. Por favor, tente novamente.',
    // step03
    // step04
    placeTitle: 'Título do anúncio',
  },
  forms: {
    sms: 'SMS',
    emailField: 'E-mail',
    firstNameField: 'Nome',
    fullNameField: 'Nome completo',
    lastNameField: 'Sobrenome',
    countryField: 'País',
    verificationCodeField: 'Código de verificação',
    zipCodeField: 'CEP',
    addressField: 'Endereço',
    addressNumberField: 'Número',
    addressComplementField: 'Complemento',
    addressNeighborhoodField: 'Bairro',
    addressStateField: 'Estado',
    addressCityField: 'Cidade',
    financialInstitutionField: 'Instituição financeira',
    accountOwnerField: 'Titular da conta',
    bankBranchField: 'Agência',
    bankAccountNumberField: 'Número da conta',
    bankAccountDigitField: 'Dígito',
    confirmPasswordField: 'Confirmar senha',

    RGField: 'RG',
    CNHField: 'CNH',
    documentPhotoFront: 'FRENTE',
    documentPhotoBack: 'VERSO',
    documentPhotoType: 'jpg ou png',

    zipcodePlaceholder: 'CEP',

    labelYes: 'Sim',
    labelNo: 'Não',
    occupationField: 'Profissão',
    occupationExpertiseField: 'Especialidade',
    workerRegisterNumber: 'Número do registro profissional',
    workerRegisterState: 'Estado emissor do registro',
    functionField: 'Função',
  },
  feedbacks: {
    somethingWentWrong: 'Algo deu errado!',
    editingSuccess: 'Alterações salvas com sucesso',
    removeSuccess: 'Removido com sucesso',
    servicesError: 'Houve um erro nos nossos serviços. Por favor, tente novamente.',
    loading: 'Carregando...',
    required: 'Obrigatório',
    requiredField: 'Campo obrigatório',
    invalidField: '{{fieldName}} inválido',
    passDoesNotMatch: 'As senhas não coincidem. Por favor, tente novamente',
    fullName: 'Nome completo obrigatório',
    fillInRequiredFields: 'Preencha os campos obrigatórios',
    youNeedToFillTheFields: 'Você precisa preencher os campos para avançar',
    selectOneOption: 'Por favor, selecione uma opção',
    copiedEmail: 'E-mail copiado',
    sentEmail: 'E-mail enviado',
    invalidEmail: 'E-mail inválido',
    invalidPhoneNumber: 'Telefone inválido',
    copyWithSuccess: 'Copiado com sucesso',
    sentWithSuccess: 'Enviado com sucesso',
    emptyFieldWarning: 'Campo obrigatório',
    invalidImages: 'Algumas imagens não podem ser exibidas por conterem erros',
    invalidImage: 'Esta imagem não pode ser exibida por conter erros',
    invalidFormat: 'Formato de arquivo não aceito. Use PNG, JPG, PDF',
    bigFiles:
      'As images selecionadas não devem exceder a 5MB (cada) e devem estar nos formatos PNG/ JPEG/JPG.',

    // validate pass strength
    passStrength: 'Força da senha:',
    strongPass: 'Forte',
    weakPass: 'Fraca',
    passRule8Chars: 'Pelo menos 8 carateres',

    passRuleNumberOrSymbol: 'Contém um número ou um símbolo',
    passwordStrength_weak: 'Força da senha: fraca',
    passwordStrength_strong: 'Força da senha: forte',
    passwordConfirmed_success: 'As senhas combinam',
    passwordConfirmed_failure: 'As senhas não combinam',
    confirmResetPasswordSuccess: 'Que bom ter você de volta! Sua senha foi atualizada.',
    confirmResetPasswordFailure:
      'Não foi possível redefinir sua senha. Por favor, tente novamente.',
    resetPasswordInvalidToken:
      'Esse link de redefinição de senha expirou. Por favor, solicite novamente para redefinir sua senha.',
    resetPasswordError:
      'Não foi possível enviar o link da redefinição de senha. Por favor, entre em contato com o suporte',
    resetPasswordSuccess: 'As instruções para redefinir sua senha foram enviadas para o seu email!',

    invalidCode: 'Código inválido. Verifique e tente novamente.',
    invalidZipcode: 'Por favor, digite um CEP válido',
    selectYourState: 'Por favor, selecione o seu estado',

    pleaseChooseFrontBackDocument: 'Por favor, coloque frente e verso do documento',

    pleaseSelectAnOccupation: 'Por favor, selecione o profissional',
    pleaseSelectASpecialty: 'Por favor, selecione a especialidade',
    youCanChooseMoreThanOneSpecialty: 'Você pode escolher mais de uma especialidade',
    pleaseTypeRegisterNumber: 'Por favor, digite o número de registro',
    pleaseSelectAIssuingState: 'Por favor, selecione o estado emissor',
    pleaseSelectAFunction: 'Por favor, selecione a função',

    explainEmailAlreadyInUse: 'Este e-mail já se encontra em uso, tente o esqueci minha senha.',
    explainEmailAlreadyInUseByAnotherProvider:
      'Este e-mail já foi cadastrado utilizando {{provider}}',
    validateYourEmail: 'Valide seu e-mail para continuar',
    yourProfilePicRequired: 'Você precisa colocar sua foto de perfil',
    errorSavingPaymentMethod:
      'Houve um erro ao salvar suas informações financeiras. Por favor, tente novamente.',

    errorSavingAddress: 'Houve um erro ao salvar seu endereço. Por favor, tente novamente.',
    invalidCpfAlert: 'Por favor, insira um cpf válido',
    invalidCnpjAlert: 'Por favor, insira um cnpj válido',
    invalidCpfOrCnpjAlert: 'Por favor, insira um cpf ou cnpj válido',

    uploadImageBeforeGoOn: 'Você deve enviar pelo menos uma imagem antes de avançar',
    getAmenitiesError: 'Houve um erro ao recuperar as conveniências. Por favor, tente novamente.',
  },
  common: {
    continue: 'Continuar',
    cancel: 'Cancelar',
    register: 'Cadastrar',
    signUp: 'Cadastre-se',
    save: 'Salvar',
    back: 'Voltar',
    next: 'Próximo',
    confirm: 'Confirmar',
    close: 'Fechar',
    charactersAlert: 'Mínimo de {{number}} caracteres',
    loadMore: 'Carregar Mais',
    moneyPrefix: 'R$',
    edit: 'Editar',
    conclude: 'Concluir',
    tryAgain: 'Tentar novamente',
    loading: 'Carregando',
    send: 'Enviar',
    backToPage_male: 'Voltar ao {{page}}',
    backToPage_female: 'Voltar a {{page}}',
    periods: 'Períodos',
    value: 'Valor',
    init: 'Início',
    end: 'Fim',
    booking: 'Reserva',
    reprove: 'Reprovar',
    aprove: 'Aprovar',
    backToNotifications: 'Voltar para notificações',
    backToMyBookings: 'Voltar para minhas reservas',
    period: 'Período',
    serviceTerms: 'Termos e Condições de Uso',
    sharing: 'Compartilhando',
    rating: 'Avaliar',
    renting: 'Alugando',
    apply: 'Aplicar',
    solicitation: 'Solicitação',
    noResultFound: 'Nenhum resultado encontrado!',
    changeTheFilterProps: 'Altere o termo de busca e tente novamente',
    couponDisclaimer: '* Este cupom sobrescreverá qualquer desconto ativo.',
  },
  signup: {
    title: 'Contecte-se',
    asideBoxTitle: 'Conecte-se',
    formTitle: 'Cadastre-se',
    signupWithMessage: 'Continuar com {{signupMode}}',
    optionsSeparatorMessage: 'ou',
    hasAnAccountMessage: 'Já tenho conta na Let Clinic!',
    hasAnAccountMessageVariation: 'Já tem conta na Let Clinic? <0>Entrar</0>',
    atTheEndYouAccept:
      'Ao finalizar o seu cadastro, você concorda com os nossos <0>Termos de Serviço da LetClinic</0> e com as <1>Políticas de Preço</1>.',
    signUpTermRejectedAlert: 'O termo de uso foi recusado',
    signUpFailureAlert:
      'Infelizmente não conseguimos completar seu cadastro. Por favor, tente novamente mais tarde',

    // signup with email
    emailAlreadyUsed: 'Já existe uma conta associada a este endereço de email',
    asideBoxSignUpWithEmail:
      'A Let Clinic aproxima você dos seus pacientes. Faça parte da nossa rede.',
    confirmButton: 'Cadastrar',
    createAccount: 'Criar conta',
    forYourSecurityValidation:
      'Para maior segurança, algumas informações podem ser autenticadas por nossa equipe.',
    benefits: {
      video: {
        title: 'Vídeo consulta',
        description: 'gratuito e sem precisar baixar aplicativos.',
      },
      sharing: {
        title: 'Modelo de saúde compartilhada',
        description: 'que gera valor às diferentes especialidades',
      },
      transparent: {
        title: 'Transparência',
        description: 'na comunicação e sistema seguro de pagamento.',
      },
    },
  },
  signUpTerm: {
    letClinicServiceTerms: 'Termos e Condições de Uso e Política de Privacidade',
    title: 'Antes de entrar',
    beforeSignUpMessage:
      'Temos o compromisso em construir uma rede segura e transparente, que possibilita novas oportunidades a nossos diferentes audiências. Para garantirmos esse ambiente, pedimos que revise nossos termos abaixo e, caso concorde com todos eles, assuma esse compromisso conosco.',
    acceptTerms: 'Aceito os <0>Termos de Serviço da Let Clinic</0> e as <1>Políticas de Preço</1>.',
    accept: 'Aceitar',
    reject: 'Recusar',
  },
  placeStage1: {
    title: 'Cadastro de espaço - Etapa 01',
    prefixed: 'Etapa 01',
    steps: {
      'one': 'Tipo de consultório',
      'two': 'Endereço',
      'three': 'Comodidades',
    },
    step01: {
      title: 'O que você está anunciando?',
      description:
        'Quanto mais você conseguir especificar as informações, mais completo será seu anúncio.',
      typeOfOffice: 'Tipo de consultório',
      typeOfSpecialties: 'Especialidade',
      notFoundSpecialties: 'Especialidade não encontrada',
      otherTypeOffice: {
        title: 'Você tem outro tipo de consultório?',
        description:
          'Certifique-se se a sua área não está especificada em alguma das opções acima. Quando você utiliza as categorias cadastradas, o preenchimento é bem mais simples.',
        newTypeTip: 'Insira o tipo de consultório, caso não esteja acima',
      },
      whatSpecialty: 'Em que especialidade?',
      searchSpecialty: 'Procurar especialidade',
    },
    step02: {
      title: 'Onde fica o seu lugar?',
      description:
        'O endereço exato do seu espaço só será disponibilizado depois que a reserva for feita.',
      usePreviousAddress: 'Utilizar endereço já cadastrado',
    },
    step03: {
      basicAmenities: {
        'one': 'Secretária',
        'two': 'Ar-condicionado',
        'three': 'Wi-fi',
        'four': 'Café',
        'five': 'Estacionamento',
        'six': 'Limpeza',
      },
      securityAmenities: {
        'one': 'Extintor de incêndio',
        'two': 'Detector de fumaça',
        'three': 'Kit de primeiros socorros',
      },
      title: 'Quais comodidades você oferece?',
      description:
        'Você poderá incluir outras comodidades que não estejam listadas aqui, desde que as mesmas estejam inclusas no valor proposto para seu espaço.',
      common: {
        description: '(Você pode incluir mais de uma comodidade, separando por vírgula)',
        placeholder: 'Comodidades que seu espaço oferece (separe por vírgula)',
        tips: 'Inserir comodidades torna seu espaço mais atrativo',
      },
      basic: {
        title: 'Comodidades básicas',
        items: {
          title: 'Insira mais comodidades básicas',
        },
      },
      security: {
        title: 'Comodidades de segurança',
        items: {
          title: 'Insira mais comodidades de segurança',
        },
      },
      equipment: {
        title: 'Equipamentos e Instrumentais',
        description:
          'Inclua os equipamentos e instrumentais que serão disponibilizados e que estão <1>inclusos no valor</1> <2>(Você pode incluir mais de um equipamento ou instrumental, separando por vírgula)</2>',
        placeholder: 'Ex: Eletrocardiograma, Estetoscópio, Medidor de pressão',
      },
    },
  },
  placeStage2: {
    title: 'Cadastro de espaço - Etapa 02',
    prefixed: 'Etapa 02',
    steps: {
      'one': 'Fotos do espaço',
      'two': 'Descrição',
      'three': 'Regras',
      'four': 'Título',
    },
    step01: {
      title: 'Fotos do espaço',
      description:
        'Essas fotos serão importantes na visualização de tudo o que seu espaço oferece. Sugerimos que você tente fazer uma boa seleção de imagens que mostre todo o potencial de sua estrutura.',
      mainPhoto: 'Foto da capa',
      uploadPhotos: 'Carregar Fotos',
      dragToUploadTip: 'Ou arraste para cá',
      canDropPhotoTip: 'Pode soltar',
      addAnotherPhotoTip: 'Adicionar outra foto',
      infoFileSize: 'Cada imagem deve ter até 5MB e devem estar nos formatos PNG/JPEG/JPG.',
    },
    step02: {
      title: 'Descreva seu espaço',
      description:
        'Faça um resumo do que há de especial em seu espaço. Coloque informações sobre o que você oferece, localização e formas de interação.',
    },
    step03: {
      title: 'Defina as regras do seu espaço',
      description:
        'Inclua aqui as regras que o seu Booker precisa seguir. Ele precisará aceitar as regras para conseguir alugar o seu espaço.',
    },
    step04: {
      placeholder: 'Título do anúncio',
      title: 'Pense em um título atrativo para seu espaço',
      description:
        'Um título atrativo ajuda a chamar a atenção dos bookers para o seu espaço. Pense o que você oferece de especial e use a criatividade.',
      helperText: 'O título do seu espaço deve conter pelo menos 5 caracteres.',
    },
  },
  placeStage3: {
    title: 'Cadastro de espaço - Etapa 03',
    prefixed: 'Etapa 03',
    steps: {
      'one': 'Solicitação de reserva',
      'two': 'Períodos disponíveis',
      'three': 'Calendário',
      'four': 'Preço',
      'five': 'Descontos',
      'six': 'Cupons',
      'seven': 'Check list',
    },
    step01: {
      title: 'Quanto tempo de antecedência você precisa para receber uma solicitação de reserva?',
      description: 'Selecione a quantidade de dias que seja suficiente para você se preparar.',
      daysBefore: '{{number}} dia{{plural}} antes',
      sameDay: 'Mesmo dia',
    },
    step02: {
      title: 'Chegou a hora de definir os horários que seu espaço estará disponível para reserva.',
      description:
        'Você pode definir seu modelo de disponibilidade por horas ou por períodos (equivalente a 4 horas de locação).',
      warning:
        'Seu anúncio ainda não será publicado. E sempre que desejar alterar o modelo de disponibilidade  por horas ou períodos, você poderá fazê-lo desde que não tenha nenhuma reserva já programada.',
      endTime: ' Horário final',
      unavailable: 'indisponível',
      period: 'Período',
    },
    step03: {
      title: 'Configure seu calendário de acordo com sua disponibilidade',
      description:
        'Clicando sobre cada item, você pode bloquear ou desbloquear o mês, dia da semana, datas ou período específicos.',
      youNeedToCompletePeriodsBeforeProceeding:
        'Você precisa cadastrar pelo menos um período antes de continuar.',
      calendarTooltip:
        'Para uma configuração padrão de bloqueios e desbloqueios, clique no menu localizado ao lado de cada período (representado pelos três pontinhos).',
      unlockAtLeastOnePeriodAtCalendar:
        'Recomendamos que você desbloqueie uma ou mais datas antes de publicar seu anúncio.',
    },
    step04: {
      title: 'Defina o preço por período de reserva',
      titleHour: 'Defina o preço por hora da reserva',
      description:
        'Sugerimos que você comece com um valor mais baixo para atrair mais reservas nesse início.',
      periodBasePriceHelper: 'Preço básico (por período de 4h)',
      hourBasePriceHelper: 'Preço básico / hora',
      earningsPerDayBasis: 'Ganho por dia (sem taxas)',
      howEarningsAreComputed:
        'O cálculo é feito com base no número de períodos que você configurou multiplicado pelo valor definido para o período. Importante: não se esqueça que o percentual Let Clinic será descontado sobre o valor final. Dúvidas: Acesse nossos Termos de Pagamento.',
      howEarningsAreComputedByHour:
        'O cálculo é feito com base no número de horas que você configurou multiplicado pelo valor definido por hora. Importante: não se esqueça que o percentual Let Clinic será descontado sobre o valor final. Dúvidas: Acesse nossos Termos de Pagamento.',
    },
    step05: {
      title:
        'Como forma opcional, você pode configurar descontos para estimular reservas mais longas.',
      description:
        'Para obter o desconto, não é necessário que os interessados em seu espaço reservem períodos ininterruptos. Basta que façam a reserva de todos os períodos no mesmo pedido.',
      XPeriods: '{{numberOfPeriods}} períodos',
      tip: '<strong>Dica:</strong> {{tip}}',
      discountForXPeriods: 'Desconto para {{numberOfPeriods}} períodos',
      priceWithDiscount: 'Preço com desconto',
      discountPercentage: '% de desconto',
      invalidDiscountPercentageValue: 'O desconto não pode ser superior a 100%',
    },
    step06: {
      title:
        'Cupons de desconto',
      description:
        'Crie e disponibilize cupons promocionais',
      code: 'Código',
      percentage: '%'
    },
  },
  placeStatus: {
    title: 'Gerenciar anúncios',
    ads: 'Anúncios',
    description: 'Descritivos',
    calendar: 'Calendário',
    priceDiscount: 'Preço/Desconto',
    announced: 'Anunciado',
    unannounced: 'Não anunciado',
    noTitle: 'Sem título',

    stepX: 'Etapa {{number}}',
    stepsOfStage1: 'Tipo de consultório, endereço e comodidades',
    stepsOfStage2: 'Fotos, descrição, regras e título',
    bookingSettingsCalendarPrice: 'Configurações de reserva, calendário, preço',

    pageTitle: 'Cadastro de Espaço - Resumo',
    youreDoingWell: 'Você está indo bem, {{name}}!',
    letDefineYourSchedule:
      'Vamos definir a sua agenda e os planos com preços e períodos de utilização',
    littleMissing: 'Falta pouco, {{name}}!',
    nowLetsDefineYourSchedule:
      'Agora vamos definir os detalhes do seu espaço para que você possa publicar seu anúncio. {{missingSteps}}',
    missingSteps: 'Ah, e não esqueça das etapas pendentes.',
    allReady: 'Tudo pronto, {{name}}!',
    littleMissingToPublish: 'Falta pouco para publicar seu anúncio, {{name}}!',
    justCompleteThePendingSteps: 'É só concluir as etapas pendentes.',
    justPublish: 'Agora, basta publicar seu anúncio.',
    inOrderToPublishCompleteYourProfile:
      'Para que você possa publicar seu anúncio e começar a receber reservas, precisamos concluir seu cadastro pessoal.',
    infoAboutYou: 'Informações sobre você',
    registrationData: 'Dados cadastrais',
    completeYourInfo: 'Complete suas informações pessoais',
    pendingInfo: 'Há informações pendentes em seu cadastro',
    infoAboutSpace: 'Informações sobre seu espaço',
    buildYourAd: 'Monte seu anúncio',
    prepareToReceive: 'Prepare-se para receber',
    howAboutPublish: 'Que tal publicar seu anúncio agora?',
    publishAd: 'Publicar anúncio',

    // pending
    ready: 'Prontinho, {{name}}!',
    createNewAd: 'Criar novo anúncio',
    yourAdHasBeenSuccessfullyRegistered: 'Seu anúncio foi cadastrado com sucesso.',
    explainPendingAd:
      'Nossa equipe irá analisar com cuidado seu anúncio e no prazo de até 48h úteis ele será publicado.',
    explainPendingAd2: 'Havendo qualquer divergência, você será informado por e-mail.',
  },
  register: {
    yourProfilePic: 'Sua foto de perfil',
    doItLater: 'Farei isso depois',
    // welcome
    welcomeTo: 'Bem-vindo(a) à',
    welcomeMessage:
      'Restam apenas 6 passos. Precisamos de mais algumas informações, e logo você vai poder explorar nossos serviços.',
    nextStep: 'Próximo',

    // photo
    thisIsYourProfilePhoto: 'Essa será sua foto de perfil',
    explainProfilePhoto: 'Verifique se ela não possui informações pessoais ou confidenciais.',
    changePhoto: 'Alterar foto',
    addYourProfilePhoto: 'Adicione sua foto de perfil',
    explainImportanceYourProfilePhoto:
      'Ela será importante numa comunicação mais segura em seu processo de reserva.',

    // phone
    confirmYourPhoneNumber: 'Confirme seu número de telefone',
    explainPhoneNumberImportance: 'Ele será importante durante todo o processo de reserva.',
    ratesMayApply: 'Podem ser cobradas tarifas padrão para o envio de mensagens e dados.',
    insert4digitsCode: 'Inserir código de 4 dígitos',
    explainSentCodeToNumber:
      'Enviamos uma mensagem de texto para <strong>{{number}}</strong>, com o nosso código de segurança. Insira ele abaixo.',
    changeMyNumber: 'Alterar meu número de telefone',
    sendCodeAgain: 'Reenviar código',
    cellphone: 'Celular',
    whyNumberPhoneIsImportant:
      'O telefone celular é importante para a comunicação durante o período de locação.',
    phoneNumber: 'Número de telefone',
    validateCellphone: 'Validar celular',
    explainSentCodeToNumberVariation:
      'Enviamos uma mensagem de texto para <strong>{{number}}</strong>, com o nosso código de segurança. Insira ele abaixo.',
    resendCode: 'Reenviar código',

    // document
    uploadDocument: 'Envie seu documento',
    explainPhotoDocumentForValidation:
      'Para validarmos seu cadastro, escolha uma das opções abaixo e envie a frente e o verso do documento.',
    explainDocumentForValidation:
      'Seu documento passará por uma validação interna. Em até 48 horas úteis, informaremos se ele foi aprovado.',
    explainHowToUploadDocument: 'Arraste e solte aqui a imagem ou <br/><1>Clique aqui</1>',
    identificationDocument: 'Documento de identificação',

    // address
    registerAddress: 'Cadastre seu endereço',
    commercialAddressType: 'Comercial',
    residentialAddressType: 'Residencial',
    address: 'Endereço',
    explainAddressRegister: 'Este é o seu endereço cadastral, e não será divulgado publicamente.',

    // occupation area
    occupationArea: 'Área de atuação',
    YourOccupationArea: 'Qual sua área de atuação?',
    explainOccupationArea:
      'A partir dela, indicaremos os melhores negócios de acordo com seu perfil.',
    imAHealthcareProfessional: 'Sou profissional da saúde',

    // email validation
    confirmYourEmail: 'Confirme seu e-mail',
    explainEmailImportance:
      'Enviaremos para esse e-mail todas as informações e recibos de suas transações.',
    thisIsTheSameEmailYouUseToLogIn: 'Este é o mesmo e-mail que você usa para fazer login.',
    confirmEmail: 'Confirmar e-mail',
    explainSentEmail: `Enviamos um um link de confirmação para <br /> <strong>{{email}}</strong> <br /> <br />
  Caso não o encontre na Caixa de Entrada do e-mail informado, pedimos que também verifique na sessão de Spam. Em alguns casos, o e-mail pode ser direcionado para esse local. <br /><br />
  <strong>Caso não receba o e-mail dentro de 2 minutos, peça o reenvio abaixo.</strong>`,
    resendEmail: 'Reenviar e-mail',
    changeEmail: 'Alterar e-mail',

    linkExpired: 'Seu link expirou',
    explainExpiredEmail: `Clique abaixo e reenviamos um novo link de confirmação no seu e-mail.
  Caso não o encontre na Caixa de Entrada do e-mail informado, pedimos que também verifique na sessão de Spam.
  Em alguns casos, o e-mail pode ser direcionado para esse local.
  <strong>Importante: o link expira em 24 horas.</strong>`,

    // explore
    allRight: 'Tudo certo!',
    pleasureToHaveYouWithUs: 'É um prazer ter você com a gente!',
    exploreMessage: 'Conheça nossa plataforma, que oferece mobilidade e otimiza recursos.',
    startExploring: 'Comece a explorar',

    // pendency
    profilePic: 'Foto de perfil',
    registrationPending: 'Pendências cadastrais',
    editProfile: 'Edição de perfil',
  },
  home: {
    ourSpecializedOffices: 'Consultórios para alugar',
    seeAllOffices: 'Veja todos os consultórios',
    knowMore: 'Saiba Mais',
    banner: {
      '1': 'Transforme o seu consultório em um Coworking da saúde!',
      '1Description':
        'Compartilhe seu consultório, otimize seus recursos e torne rentável seu espaço ocioso.',
      '2': ' Consultório online para atender os seus pacientes por vídeo consulta.',
      '2Description': 'É gratuito e não precisa baixar aplicativos.',
      '3': 'Rede compartilhada de recursos em diversas áreas da saúde',
    },
    trustNetwork: {
      title: 'Rede confiável de profissionais da saúde',
      explainTrustNetwork:
        'Através do modelo de economia compartilhada, a Let Clinic apresenta a combinação dos pilares dos quatro cês, trazendo uma nova oportunidade aos diferentes profissionais da área da saúde.',
      creation: 'Criação de valor',
      creationExpplain:
        'A Let Clinic contribui para otimizar os recursos da área da saúde, tornando rentável seu espaço ocioso e possibilitando a mobilidade do local de trabalho.',
      convenience: 'Conveniência',
      convenienceExplain:
        'Na Let Clinic você pode compartilhar ou alugar consultórios e equipamentos de outros profissionais para receber seus pacientes a qualquer hora e em qualquer lugar.',
      community: 'Comunidade',
      communityExplain:
        'Todos os cadastros na plataforma são gratuitos e analisados com cuidado, através de uma curadoria rigorosa, tornando a rede segura.',
      trust: 'Confiança',
      trustExplain:
        'Toda a comunicação, pagamento e avaliações são feitas através da plataforma: uma interação simples, flexível e segura.',
    },
    howItWorks: {
      title: 'Como funciona?',
      step1: '1',
      explainStep1:
        'Cadastre-se gratuitamente e faça parte de nossa rede. Depois de cadastrado você pode compartilhar e/ou explorar consultórios, de acordo com sua especialidade.',
      step2: '2',
      explainStep2:
        'Caso compartilhe seu consultório, defina as regras de utilização, controle e disponibilidade. No caso de alugar um espaço, escolha o melhor local, data e preço. Tudo com total autonomia.',
      step3: '3',
      explainStep3:
        'Quando confirmado o acordo, aguarde a data e compareça ou organize o consultório para atender, com a melhor experiência possível, o seu cliente.',
    },
    sharedHealthModel: {
      title: 'Novo modelo de saúde compartilhada',
      explainSharedHealthModel:
        'Imagine um lugar seguro em que profissionais da saúde compartilham seus espaços, recursos e experiências. Todos conectados neste mundo onde você pode cuidar em qualquer lugar.',
    },
    advantages: {
      title: 'Vantagens do Let Clinic',
      step1: 'Aproveite o modelo de Custo Variável',
      explainStep1:
        'Tenha a conveniência de um modelo sem custo ou investimento inicial, em que a remuneração sobre o ativo é baseada em seu real aproveitamento.',
      step2: 'Tenha um consultório sem precisar tê-lo',
      explainStep2:
        'Acesso a serviços de alto custo, por preços mais acessíveis através da lógica colaborativa de coworking da saúde.',
      step3: 'Escolha o seu local de atendimento',
      explainStep3:
        'Melhore a experiência do seu atendimento, baseada na flexibilidade do local de trabalho. Aproximando você de seus pacientes.',
    },
    searchBox: {
      search: 'Buscar',
      searchBoxText: 'O que você procura (ex. dermatologista)',
      bookAWorkspaceWithEverythingYouNeed: 'Encontre aqui espaços e recursos próximos a você',
      where: 'Onde',
      whereNeighborhoodCityAndOrState: 'Onde (Bairro, Cidade e/ou Estado)',
      typeOfOffice: 'Tipo de consultório',
      specialty: 'Especialidade',
      minDateMessage: 'Por favor, selecione uma data válida',
      bookingDate: 'Período de busca',
      reservationPeriod: 'Período de reserva',
    },
  },
  search: {
    map: 'Mapa',
    showMap: 'Exibir mapa',
    spacesAvailableIn: 'Espaços disponíveis em',
    period: 'período',
    hour: 'hora',
    period4h: 'Período = 4 horas',
    rentByHour: 'Locação por hora',
    date: 'Data',
    expertise: 'Tipo de consultório',
    specialties: 'Especialidade',
    price: 'Preço',
    searchResult: 'Resultado da busca',
    nothingFound: 'No momento não encontramos nenhum espaço com os critérios de busca.',
    redefineSearch: 'Redefinir busca',
  },
  rating: {
    pontualidade: 'Pontualidade',
    limpeza: 'Limpeza',
    valor: 'Valor',
    comunicacao: 'Comunicação',
    estrutura: 'Estrutura',
    geral: 'Avaliação Geral',
  },
  booking: {
    explainLocationPrecision:
      'Por motivo de segurança, o local exato é fornecido depois da reserva finalizada.',
    clinic: 'Consultório',
    description: 'Descrição',
    conveniences: 'Comodidades',
    securityConveniences: 'Comodidades de Segurança',
    equipConveniences: 'Equipamentos e Instrumentais',
    rulesWorkplace: 'Regras',
    availability: 'Oferta Especial',
    specialDiscounts: 'Descontos especiais',
    checkIt: 'Confira',
    periodsAvailable: '{{hoster}} disponibiliza seu espaço para os períodos',
    hoursAvailable: '{{hoster}} disponibiliza seu {{equipment}} durante os horários de',
    periodSelector: 'Selecione o(s) período(s) que deseja fazer sua reserva.',
    hourSelector: 'Selecione o(s) horário(s) que deseja fazer sua reserva.',
    periodsAvailableTime: '{{start}}h às {{end}}h',
    localization: 'Localização',
    workplaceIsLocateIn:
      'O espaço de {{name}} está localizado em {{neighborhood}}, {{city}}/{{state}}',
    booking: 'Reservar',
    myBookings: 'Minhas Reservas',
    explainDiscount: 'Este espaço oferece descontos especiais para reservas acima de 15 períodos.',
    discountsByPeriod: '- {{period}} períodos: {{discount}}% de desconto',
    totalDiscounts: '{{value}} de desconto',
    total: 'Total',
    dates: 'Datas:',
    showMore: 'Mostrar Mais',
    showLess: 'Mostrar Menos',
    nextMonths: 'Próximos meses',
    mySelections: 'Minhas seleções',
    myPeriods: 'Meus períodos',
    finishBooking: 'Finalizar reserva',
    chooseMorePeriods: 'Escolher mais datas',
    toFinishClickInFinish: 'Para concluir suas reservas, clique em Finalizar Reserva',
    untilNowYoullPay: 'Até o momento suas reservas estão calculadas em:',
    beforeFinishCheck: 'Antes de finalizar, certifique que sua reserva está correta.',
    agreeAndBook: 'Concordar e Reservar',
    cancelPolicy: 'Política de cancelamento',
    cancelPolicyDescriptionWithLink:
      'Cancelamento gratuito em até 48 horas antes do check-in de reservas aprovadas. Após esse prazo, será cobrado uma multa no valor de um período. Ao clicar no botão abaixo, você concordará com os <0>Termos e Condições de Uso</0>',
    cancelPolicyByHoursDescriptionWithLink:
      'Cancelamento gratuito em até 48 horas antes do check-in de reservas aprovadas. Após esse prazo, será cobrado uma multa no valor de uma hora. Ao clicar no botão abaixo, você concordará com os <0>Termos e Condições de Uso</0>',
    cancelPolicyEquipmentDescriptionWithLink:
      'Cancelamento gratuito até a assinatura do contrato de reservas aprovadas. Este contrato será firmado após a aprovação da solicitação da reserva. Após a assinatura, será cobrada uma multa de 50% do valor da locação. Ao clicar no botão abaixo, você concordará com os <0>Termos e Condições de Uso</0>',
    cancelPolicyDescription:
      'Cancelamento gratuito em até 48 horas antes do check-in de reservas aprovadas. Após esse prazo, será cobrado uma multa no valor de um período.',
    cancelPolicyByHoursDescription:
      'Cancelamento gratuito em até 48 horas antes do check-in de reservas aprovadas. Após esse prazo, será cobrado uma multa no valor de uma hora.',
    cancelPolicyEquipmentDescription:
      'Cancelamento gratuito até a assinatura do contrato de reservas aprovadas. Este contrato será firmado após a aprovação da solicitação da reserva. Após a assinatura, será cobrada uma multa de 50% do valor da locação.',
    cancelPolicyConfirmation: ' Ao clicar no botão abaixo, você concordará com os',
    canceledMessage: 'Sua reserva foi cancelada com sucesso!',
    approvedErrorMessage: 'Não foi possível Aprovar a Reserva',
    reprovedErrorMessage: 'Não foi possível Reprovar a Reserva',
    canceledErrorMessage: 'Não foi possível Cancelar a Reserva',
    confirmationReprove: 'Tem certeza que deseja recusar essa reserva?',
    confirmationCancel: 'Tem certeza que deseja cancelar sua reserva?',
    yesCancel: 'Sim, cancelar',
    yesRecused: 'Sim, recusar',
    backToBooking: 'Voltar para reserva',
    bookingSolicitation: 'Solicitação de Reserva',
    hasNoBookings: 'Você ainda não tem nenhuma reserva!',
    newBookingRoom: 'Reservar novo espaço',
    newBooking: 'Nova reserva',
    cancel: 'Cancelar Reserva',
    successRegister: 'Reserva registrada com sucesso! ;)',
    getReadyToReceivePartner: 'Prepare-se para receber seu parceiro da melhor forma.',
    orderBooking: 'Reservas Programadas',
    recusedBooking: 'Reserva recusada com sucesso!',
    statusYourBookings: 'Status das suas reservas',
    searchPlaceholder: 'Você pode buscar pelo nome, número da reserva ou consultorio!  ;)',
    bookingStatus: 'Status da reserva',
    approveError: 'Não foi possível aprovar esta reserva',
    infoHosterFinancialRegister:
      'Para que você possa aprovar suas reservas, é necessário cadastrar uma forma de recebimento.',
    registerNewHosterFinancial: 'Cadastrar forma de recebimento',
    refunds: {
      booker: {
        value: 'Valor',
        total: 'Total da reserva',
        refundValue: 'Estornado',
        refundTotalDescription: 'O valor da reserva será estornado em breve.',
        refundPartiallyDescription:
          'O valor da reserva será estornado em breve já com o desconto da multa de 1 período conforme a política de cancelamento.',
        refundByHourPartiallyDescription:
          'O valor da reserva será estornado em breve já com o desconto da multa de 1 hora conforme a política de cancelamento.',
        noRefundDescription:
          'Não há valor a ser estornado. Conforme nossa política de cancelamento, foi gerado a multa no valor de 1 período.',
        noRefundByHourDescription:
          'Não há valor a ser estornado. Conforme nossa política de cancelamento, foi gerado a multa no valor de 1 hora.',
      },
      hoster: {
        value: 'Valor',
        total: 'Total da reserva',
        refundValue: 'Estornado ao parceiro',
        refundTotalDescription:
          'Conforme nossa política de cancelamento, as reservas podem ser canceladas pelo parceiro sem nenhum custo quando a mesma for realizada no prazo de até 48h antes do primeiro período.',
        refundPartiallyDescription: `Conforme nossa política de cancelamento, as reservas podem ser canceladas pelo parceiro sem nenhum custo quando a mesma for realizada no prazo de até 48h antes do primeiro período contratado.
          <br /><br /> Após esse prazo, fica acordado que, o mesmo deverá pagar uma multa ao proprietário do espaço no valor correspondente a 1 período.
          Em breve será creditado em sua conta o valor referente a multa.`,
        noRefundDescription: `Conforme nossa política de cancelamento, as reservas podem ser canceladas pelo parceiro sem nenhum custo quando a mesma for realizada no prazo de até 48h antes do primeiro período contratado.
          <br /><br /> Após esse prazo, fica acordado que, o mesmo deverá pagar uma multa ao proprietário do espaço no valor correspondente a 1 período.
          Em breve será creditado em sua conta o valor referente a multa.`,
      },
    },
    status: {
      pending: 'Pendente',
      pendingTitle: 'Seu pedido de reserva foi enviado',
      pendingText: 'Esta não é uma reserva confirmada. Você irá receber uma resposta em até 24h.',
      pendingPaymentText:
        'Esta não é uma reserva confirmada. Faça o pagamento para prosseguir com a reserva.',
      approved: 'Aprovada',
      approvedTitle: 'Sua reserva está confirmada',
      approvedText:
        'Tudo certo com sua reserva. Agora é só anotar na agenda e ter uma excelente experiência!',
      canceled: 'Cancelada',
      canceledTitle: 'Sua reserva foi cancelada',
      canceledText: 'Seu pedido de cancelamento foi realizado com sucesso.',
      expired: 'Expirado',
      expiredText: 'Seu pedido de reserva expirou',
      reproved: 'Reprovada',
      reprovedTitle: 'Sua reserva foi reprovada',
      reprovedText: 'Lamentamos informar que sua reserva não foi aprovada.',
      inProgress: 'Em andamento',
      scheduled: 'Programada',
      closed: 'Encerrada',
    },
    filter: {
      all: 'Todas',
      inProgress: 'Em andamento',
      scheduled: 'Programadas',
      closed: 'Encerradas',
      canceled: 'Canceladas',
      reproved: 'Reprovadas',
      pending: 'Pendentes',
    },
  },
  contact: {
    wantToTalkLetclinic: 'Quero falar com a Let Clinic',
    contactOurTeam: 'Entre em contato com nossa equipe',
  },
  account: {
    account: 'Conta',
    registeredAccount: 'Conta cadastrada',
    userNameAndEmail: '<strong>{{name}}</strong>, {{email}}',
    paymentMethods: {
      paymentMethods: 'Informações financeiras',
      explainPaymentMethods: 'Acesse suas informações de pagamento e recebimento',
      paymentsAccountType: 'Pagamentos',
      receiptsAccountType: 'Recebimentos',
      explainingReceiptsAccount:
        'Quando você recebe o pagamento por uma reserva, nos referimos a ele como "Recebimento".',
      receiptsAccountDisclaimer:
        '<strong>Para receber o valor das reservas, adicione uma forma de pagamento.</strong><br/> Nosso sistema de pagamento segue todas as regras de segurança que podem ser configuradas abaixo. A Let Clinic libera o pagamento todo dia 15 do mês subsequente à data de check-in da reserva. O tempo que o valor leva para aparecer em sua conta dependerá da sua forma de recebimento.',
      changeReceiptsAccount: 'Adicionar forma de recebimento',
      bankAccount: 'Conta bancária',
      verifyingAccountState: 'Pendente',
      successAccountState: 'Pronto',
      failureAccountState: 'Reprovado',
      insertAddress: 'Inserir endereço',
      verifyingAccount:
        '<strong>Você adicionou uma forma de pagamento.</strong><br/>O processo de verificação leva, em média, {{numberOfDays}} dias úteis. Depois que seu status estiver ativado ("Pronto"), o pagamento será feito na conta configurada no menu "Forma de Recebimento".',
      verifyingAccountSuccess: '<strong>Sua conta foi validada com sucesso.</strong>',
      verifyingAccountFailure:
        '<strong>Sua conta não foi validada. Consulte seu banco para mais informações.</strong>',
      accountNeedAAddress:
        '<strong>Você precisa inserir seu endereço bancário para que sua conta seja avaliada.</strong>',
    },
    newPaymentMethod: {
      address: 'Endereço',
      newPaymentMethod: 'Cadastro de informações financeiras',
      bankAccountData: 'Dados bancários',
      explainBankAccount:
        'Insira os dados da sua conta bancária para recebimento e aguarde a confirmação e liberação da conta.',
      accountType: 'Tipo de conta',
      checkingAccountType: 'Corrente',
      savingsAccountType: 'Poupança',
      accountOwnerIdentifier: 'CPF / CNPJ',
      disclaimerAccountOwnerIdentifier: '(Número sem ponto ou hífen)',

      bankingAccountAddressDisclaimer:
        '<strong>Adicione o endereço cadastrado no banco ou na instituição financeira.</strong> É possível que ele seja diferente do endereço que você cadastrou anteriormente.',
    },
  },
  changePassword: {
    typeYourEmail: 'Digite seu e-mail',
    resetPassword: 'Redefinir Senha',
    confirmResetPasswordGuide:
      'Sua senha precisa ter pelo menos um símbolo ou número e ter pelo menos 8 caracteres.',
    resetPasswordGuide:
      'Insira o endereço de e-mail da sua conta que lhe enviaremos um link para redefinir sua senha.',
    sendResetLink: 'Redefinir senha',
    confirmResetPasswordDisclaimer:
      'Ao clicar em enviar você confirma que aceita os <0>Termos e Condições de Uso</0> e a <1>Política de Privacidade</1>.',
  },
  payment: {
    creditCard: 'Cartão de crédito',
    pix: 'PIX',
  },
  meeting: {
    startVideoConsultation: 'Iniciar vídeo consulta',
    signUpForFree: 'Cadastre-se gratuitamente',
    onlineOffice: 'Consultório On-line',
    banner: {
      welcomeToYourOnlineOffice: 'Bem-vindo ao seu consultório on-line',
      aNewWayToConnectToYourPatientsInAPracticalSafeAndEfficientWay:
        'Uma nova maneira de se conectar aos seus pacientes de modo prático, seguro e eficiente.',
    },
    enumeratedTopics: {
      title: 'Como funciona',
      1: 'Cadastre-se gratuitamente na plataforma com os seus dados.',
      2: 'Combine com seu paciente um horário e data para o atendimento.',
      3: 'No dia do atendimento, clique em vídeo conferência pelo menos 15 minutos antes da consulta e envie o link da sala para seu paciente por e-mail e/ou WhatsApp. Seu paciente não precisa baixar nenhum aplicativo para acessar a sala.',
    },
    room: {
      welcomeTo: 'Bem-vindo ao seu',
      typeThePatientName:
        'Digite o <strong>Nome do Paciente</strong> e em seguida clique em <strong>Criar Vídeo Consulta</strong>',
      onlineOffice: 'Consultório On-line',
      createVideoConsultation: 'Criar vídeo consulta',
    },
    hints: {
      title: 'Dicas de boas práticas para uma vídeo consulta',
      step01Title: '1. Estabilidade',
      step01Description:
        'Se necessário, use um suporte para deixar a câmera do computador na altura dos olhos.',
      step02Title: '2. Iluminação',
      step02Description: 'Garantir que esteja num ambiente com iluminação adequada.',
      step03Title: '3. Posicionamento',
      step03Description:
        'Buscar um enquadramento para que seu olhar esteja direcionado para a câmera, para assim o paciente se sentir acolhido.',
      step04Title: '4. Som',
      step04Description:
        'Utilize fones de ouvido para garantir a melhor qualidade na sua fala. Se estiver usando fones bluetooth, se certificar de que eles estejam carregados.',
      step05Title: '5. Conexão',
      step05Description: 'Certifique-se de que você esteja com uma conexão de boa qualidade.',
      careInTheVideoConsultation: 'Cuidados na vídeo consulta',
      explainCareInTheVideoConsultation:
        'Quando gerar o link para seu consultório on-line, acesse com alguns minutos de antecedência para testar a qualidade de sua conexão. Certifique-se do envio do link da sala para seu paciente. Sabendo que ele pode acessar a partir do momento do recebimento. Não precisa baixar aplicativos para acessar a sala. Por isso, avise seus pacientes sobre essa questão e peça para o acesso ser feito pelo navegador.',
      isTheVideoConsultationSafe: 'A vídeo consulta é segura?',
      explainIsTheVideoConsultationSafe:
        'Para cada consulta e/ou pessoa é gerada uma nova sala on-line com total autonomia do usuário de nossa plataforma. Nessa sala, você tem o controle no compartilhamento do link e dos acessos.',
      howToCloseTheRoomOrControlAccess: 'Como fechar a sala ou controlar os acessos?',
      explainHowToCloseTheRoomOrControlAccess:
        'No canto superior a esquerda, você vai encontrar um cadeado. Ele aberto, possibilita a entrada de qualquer pessoa com o link enviado por você. Já com o cadeado fechado, para o paciente acessar a sala, é preciso de sua autorização quando estiver on-line.',
    },
    ongoing: {
      hello: 'Olá',
      doctor: 'Dr(a). {{name}}',
      appointment: 'Consulta com {{name}}',
      youAreAlreadyInYourOnlineOffice: 'Você já está no seu consultório on-line.',
      sendInvitationEmail: 'Enviar convite por e-mail',
      resendInvitationEmail: 'Reenviar convite por e-mail',
      copyInvitationLink: 'Copiar link do convite',
      explainCopyLink:
        '<strong>Copiar Link do Convite</strong> - Clique em <strong>Copiar link do Convite</strong> e cole na mensagem para enviar por e-mail ou whatsapp ao seu paciente.',
      explainSendLink:
        '<strong>Enviar convite por e-mail</strong> - Clique em <strong>E-mail</strong>, em seguida digite o e-mail do seu paciente e pode enviar.',
      explainSendSms:
        '<strong>Enviar convite por SMS</strong> - Clique em <strong>SMS</strong>, em seguida digite o número do celular com DDD.',
      questionsAboutHowToSendTheInvitationToYourPatient:
        'Dúvidas de como enviar o convite ao seu paciente?',
      questionsAboutHowToSendTheInvitationToYourPatientMobile: 'Em caso de dúvidas,',
      clickHere: 'clique aqui',
      weHelpYou: 'que ajudamos você!',
      endConsultation: 'Encerrar consulta',
      endConsultationMobile: 'Encerrar',
    },
    appointment: {
      exitRoom: 'Sair da sala',
      welcomeToRoom: 'Bem-vindo ao consultório on-line do Dr(a). {{name}}',
    },
  },
  notifications: {
    title: 'Notificações',
    hasNoNotifications: 'Você não tem novas notificações',
    filter: {
      pending: 'Pendentes',
      every: 'Todas',
      apply: 'Aplicar',
    },
    status: {
      rating: 'Avaliação',
      ratingReminder: 'Lembre-se de avaliar a sua reserva!',
      ratingDoneAlert: 'Você recebeu uma avaliação!',
      reprovedByAdmin: 'Reserva Cancelada',
      booker: {
        pix: 'Seu pedido de reserva está aguardando pagamento',
        expired: 'Seu pedido de reserva expirou',
        pending: 'Seu pedido de reserva foi enviado',
        approved: 'Reserva Aprovada',
        canceled: 'Reserva Cancelada',
        reproved: 'Reserva Reprovada',
        closebyday: 'Falta 1 dia para a reserva',
      },
      hoster: {
        pending: 'Solicitação de Reserva',
        approved: 'Reserva Aprovada',
        canceled: 'Reserva Cancelada',
        reproved: 'Reserva Reprovada',
        closebyday: 'Falta 1 dia para a reserva',
      },
    },
  },
  chats: {
    title: 'Mensagens',
    hasNoChats: 'Você não tem novas mensagens',
    roomSince: 'O espaço de {{name}} está cadastrado desde {{date}}.',
    userSince: 'Membro cadastrado desde {{date}}.',
    talkTo: 'Falar com ',
    hello: 'Olá,',
    responds: 'geralmente responde dentro de algumas horas.',
    placeholderMessage: 'Digite sua mensagem',
    successMessage: 'Sua mensagem foi enviada com <span>sucesso</span>.',
    errorMessage: 'Infelizmente ocorreu um <span>erro</span>. Tente novamente!',
    alreadyChatMessage: 'Já existe uma <span>conversa ativa</span> para esta reserva.',
    showChat: 'Ver conversa',
    archivedChat: 'Arquivar conversa',
    archivedSuccess: 'Conversa arquivada com sucesso!',
    archivedError: 'Não foi possível arquivar essa conversa, tente novamente.',
    filter: {
      active: 'Ativas',
      archived: 'Arquivadas',
      apply: 'Aplicar',
    },
  },
  expertise: {
    dentistry: 'Odontologia',
    medic: 'Médico',
    psychology: 'Psicologia',
    physioterapy: 'Fisioterapia',
    phono: 'Fonoaudiologia',
    nutrition: 'Nutrição',
  },
  searchRoomField: {
    placeholder: 'Onde você quer atender?',
  },
  newHome: {
    banner: {
      booker: {
        texts: [
          'Atenda seus pacientes',
          'de onde quiser!',
          'Encontre o consultório ideal para você com mais flexibilidade e sem despesa fixa',
        ],
        buttonText: 'Quero alugar',
      },
      hoster: {
        texts: ['Ganhe uma renda extra com o seu consultório', 'Total autonomia e segurança'],
        buttonText: 'Comece já',
      },
    },
    clinicShare: {
      texts: [
        'Um jeito inteligente de compartilhar seu consultório',
        'Com a Let Clinic, seu consultório é rentável mesmo quando você não está atendendo!',
      ],
      buttonText: 'Quero anunciar',
    },
    tabs: {
      booker: {
        label: 'Quero alugar',
      },
      hoster: {
        label: 'Quero anunciar',
      },
    },
    expertise: {
      booker: {
        title: {
          regularText: 'Tipos de',
          highlightedWord: 'consultório',
        },
      },
      hoster: {
        title: {
          regularText: 'Tipos de',
          highlightedWord: 'consultório',
        },
      },
    },
    ourRooms: {
      title: {
        regularText: 'Espaços para',
        highlightedWord: 'alugar',
      },
      buttonText: 'Explorar todos',
    },
    steps: {
      booker: {
        title: {
          regularText: 'É muito simples',
          highlightedWord: 'alugar',
        },
        topics: [
          'Use os filtros e encontre o espaço ideal para você',
          'Defina a data e o período que deseja alugar',
          'Faça seu cadastro',
          'Escolha a forma de pagamento',
          'Clique em finalizar. Prontinho!',
        ],
      },
      hoster: {
        title: {
          regularText: 'É muito simples',
          highlightedWord: 'anunciar',
        },
        topics: [
          'Faça seu cadastro na plataforma ',
          'Em seguida crie seu anúncio gratuitamente com total autonomia de disponibilidade, preço e regras',
          'Clique em Publicar',
          'Seu anúncio será analisado pela nossa equipe e após aprovação, seu espaço estará pronto para ser alugado.',
        ],
      },
      equipments: {
        title: {
          regularText: 'É muito simples',
          highlightedWord: 'anunciar',
        },
        topics: [
          'Escolha o equipamento que deseja alugar',
          'Insira o endereço da entrega',
          'Defina a data e período que deseja alugar',
          'Faça seu cadastro ou login na plataforma',
          'Escolha a forma de pagamento',
          'Clique em finalizar e aguarde a aprovação para assinatura do contrato',
        ],
      },
    },
    advantages: {
      booker: {
        title: {
          regularText: 'Vantagens de',
          highlightedWord: 'alugar',
        },
        topics: [
          {
            mainText: 'Atenda seus pacientes de onde quiser',
            hiddenText: 'Você pode escolher qual cidade e bairro que quer atender.',
          },
          {
            mainText: 'Alugue por períodos de 4h',
            hiddenText:
              'Mais flexibilidade. Oferecemos períodos específicos para que você possa evitar qualquer desperdício de tempo. Além é claro, de poder atender em outros consultórios em um único dia.',
          },
          {
            mainText: 'Tenha um consultório completo sem despesas fixas',
            hiddenText:
              'Alugar com a Let Clinic é ter liberdade, flexibilidade de atender onde quiser. E sem burocracia. É só escolher o lugar, os períodos, fazer o pagamento e pronto, já pode se preparar para atender seus pacientes com tranquilidade e segurança.',
          },
          {
            mainText: 'Reserve com total segurança',
            hiddenText:
              'Nós temos uma equipe dedicada na curadoria que analisa rigorosamente a identificação oficial de cada membro da plataforma e de todos os espaços cadastrados.',
          },
        ],
      },
      hoster: {
        title: {
          regularText: 'Vantagens de',
          highlightedWord: 'anunciar',
        },
        topics: [
          {
            mainText: 'Ganhe uma renda extra mesmo quando não estiver atendendo',
            hiddenText:
              'Quer tirar férias ou até mesmo ter mais tempo livre e continuar ganhando? Com a Let Clinic você compartilha seu consultório com outros profissionais da saúde com total segurança e garantia de pagamento.',
          },
          {
            mainText:
              'Você pode disponibilizar seu espaço inclusive aos finais de semana e feriados ',
            hiddenText:
              'Total liberdade para escolher quais dias e períodos você deseja disponibilizar seu consultório.',
          },
          {
            mainText: 'Total autonomia na criação do seu anúncio',
            hiddenText:
              'Você tem total flexibilidade para definir as regras de utilização, disponibilidade de calendário e preço.',
          },
          {
            mainText: 'Anuncie seu consultório com total segurança',
            hiddenText:
              'Nós temos uma equipe dedicada na curadoria que analisa rigorosamente a identificação oficial de cada membro da plataforma.',
          },
          {
            mainText: 'Sem dor de cabeça na hora de receber',
            hiddenText:
              'O seu pagamento será liberado no dia 15 do mês subsequente da data de início da reserva. Os valores repassados serão referentes às transações de sucesso realizadas no mês anterior.',
          },
        ],
      },
      equipments: {
        title: {
          regularText: 'Vantagens de',
          highlightedWord: 'alugar  um equipamento',
        },
        topics: [
          {
            mainText: 'Acesse tecnologia de ponta, pagando apenas pelo uso',
            hiddenText:
              '',
          },
          {
            mainText:
              'Mais rentabilidade com equipamentos de alta performance',
            hiddenText:
              '',
          },
          {
            mainText: 'Aumente o seu faturamento mensal ',
            hiddenText:
              '',
          },
          {
            mainText: 'Sem gastos com manutenção',
            hiddenText:
              '',
          },
          {
            mainText: 'Aparelhos autorizados pela ANVISA',
            hiddenText:
              '',
          },
          {
            mainText: 'Ofereça as principais inovações tecnológicas para os seus clientes',
            hiddenText:
              '',
          },
        ],
      },
    },
    howItWorks: {
      buttonText: 'Saiba mais',
      title: {
        regularText: 'Como funciona a',
        highlightedWord: 'Let Clinic',
      },
      topics: [
        {
          title: 'Como funciona a Let Clinic',
          text:
            'Bem-vindo(a) à comunidade de compartilhamento de espaços da área da saúde. A nossa plataforma conecta de forma simples, rápida e segura os proprietários de consultórios com os profissionais da saúde que desejam alugar estes espaços. Mais rentabilidade, flexibilidade e segurança através de uma curadoria rigorosa.',
        },
        {
          title: 'Quer alugar um consultório?',
          text:
            'Atenda seus pacientes de onde quiser pagando apenas por períodos utilizados. É possível escolher seu consultório a partir da cidade, bairro, preços e até mesmo por períodos curtos e específicos. É mais flexibilidade para você e mais comodidade para seus pacientes.',
        },
        {
          title: 'Quer anunciar um consultório?',
          text:
            'Com a Let Clinic, você pode disponibilizar seu consultório 7 dias por semana divididos em até 3 períodos de 4h por dia. Além de seguro, você tem total autonomia para definição de regras, datas, horários e preços. É uma ótima maneira de tornar rentável seu espaço ocioso.',
        },
      ],
    },
    knowBetter: {
      title: {
        normalText: 'Conheça',
        highlightedWord: 'Melhor',
      },
      info: [
        'Bem-vindo(a) à comunidade de compartilhamento de espaços da área da saúde.',
        'A nossa plataforma conecta de forma simples, rápida e segura os proprietários de consultórios com os profissionais da saúde que desejam alugar estes espaços. Mais rentabilidade, flexibilidade e segurança através de uma curadoria rigorosa.',
      ],
    },
    footer: {
      linkBlocks: {
        letclinic: {
          title: 'Let Clinic',
          links: {
            about: 'Sobre nós',
            help: 'Ajuda',
            politics: 'Políticas de uso',
            support: 'Suporte',
            workWithUs: 'Trabalhe conosco',
          },
        },
        ourNetwork: {
          title: 'Nossa rede',
          links: {
            iWantToRent: 'Quero Alugar',
            iWantToShare: 'Quero Anunciar',
            signup: 'Cadastre-se',
          },
        },
      },
    },
  },
};

export default locale;
