import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CSSBaseline from '@material-ui/core/CssBaseline'
import { useTheme } from '@material-ui/core/styles'

const C6CssBaseLine = props => {
  const theme = useTheme()
  makeStyles({
    '@global': {
      body: {
        backgroundColor: `${theme.palette.background.default} !important`,
        fontFamily: theme.typography.fontFamily,
      },
      a: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  })()

  return <CSSBaseline {...props} />
}

export default C6CssBaseLine
