const sizes = {}

sizes.font = {}
sizes.font.tiny = 10
sizes.font.little = 12
sizes.font.smallest = 14
sizes.font.normal = 16
sizes.font.small = 21
sizes.font.medium = 28
sizes.font.large = 32
sizes.font.largest = 36
sizes.font.big = 44
sizes.font.biggest = 52

export default Object.freeze(sizes)
