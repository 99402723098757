import colors from '../colors'

export default {
  primary: {
    contrastText: colors.white,
    main: colors.lightBlue,
  },
  secondary: {
    contrastText: colors.white,
    main: colors.lightGreen,
  },
  success: {
    icon: colors.icon.success,
    main: colors.success,
  },
  info: {
    icon: colors.icon.info,
    main: colors.info,
    background: colors.backgroundInfo,
  },
  warning: {
    icon: colors.icon.warning,
    main: colors.warning,
    background: colors.backgroundWarning,
  },
  error: {
    icon: colors.icon.error,
    main: colors.icon.error,
    background: colors.error,
  },
  text: {
    primary: colors.graphite,
    secondary: colors.gray79,
    lightSecondary: colors.grayA6,
    disabled: colors.graphite60,
    link: colors.lightBlue,
    detail: colors.lightGraphite,
  },
  bg: {
    main: colors.gray,
    secondary: colors.white,
    blue: colors.denim15,
    aqua: colors.aqua,
    gray: colors.grayF5,
    darkBlue: colors.darkBlue,
  },
  buttons: {
    border: colors.border,
    borderHover: colors.borderHover,
  },
  calendar: {
    background: colors.grayF5,
    backgroundSecondary: colors.lightGreen15,
  },
}
