import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';

import LCProvider from '@lc/lib/Provider';
// using the hook direct because it agnostic of context
// since here I need the information that is only available inside of the Provider
import useUser from '@lc/lib/Provider/hooks/useUser';

import { Theme } from '@lc/legacy/src/Theme';
import MetaDescription from '@lc/ui/template/MetaDescription';
import ErrorBoundary from '@lc/ui/template/ErrorBoundary';

import 'config/i18n';
import { TabsProvider } from 'contexts/Tabs/provider';
import { FilterBookingsProvider } from 'contexts/Booking/Filter/provider';
import { OffsetPageProvider } from 'contexts/Pagination/provider';
import { SnackbarProvider } from 'contexts/Snackbar/provider';

import Snackbar from 'components/Snackbar';

import sentryConfig from '../config/sentry-config';

import '../components/Carousel/carousel.scss';
import '@lc/legacy/src/template/CalendarSelector/Calendar/sass/styles.scss';
import '../components/DayPicker/calendarPicker.scss';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import '@lc/ui/styles/globals.scss';
import 'moment/locale/pt-br';
/**
 * I didn't have time to check a fix for it
 * I think  PersistGate avoids some server side rendering,
 * if I remove that, every part of the code with `window` or other thing for client side will break
 * Probably this leaded to this error that need to be fix
 */
const createStore = () => {
  const persistedReducer = persistReducer(
    {
      key: 'persist-reducer-test',
      version: 1,
      storage,
    },
    () => ({}),
  );

  const store = configureStore({
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    reducer: persistedReducer,
    devTools: true,
  });

  const persistor = persistStore(store);

  return { store, persistor };
};
const { store, persistor } = createStore();

const App = ({ Component, pageProps }) => {
  const [queryClient] = useState(() => new QueryClient());
  const { isLogged, userData } = useUser();

  const { metaTags } = pageProps;

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    sentryConfig.init();
    if (isLogged) {
      sentryConfig.setUser(userData?.user?.email);
    } else {
      sentryConfig.removeUser();
    }
  }, [isLogged, userData?.user?.email]);

  return (
    <LCProvider>
      <Provider store={store}>
        {metaTags ? (
          <MetaDescription
            title={metaTags.title}
            description={metaTags.description}
            image={metaTags.image}
            url={metaTags.url}
          />
        ) : (
          <MetaDescription
            title="Let Clinic - Alugue - Consultórios Completos - Pague pelo uso"
            description="Quer alugar um consultório? Atenda seus pacientes de onde quiser pagando apenas por períodos utilizados. Seu consultório completo sem despesas fixas e total segurança!"
            image="https://letclinic.com/images/metaimage.jpg"
            url="https://letclinic.com/"
          />
        )}

        <PersistGate loading={null} persistor={persistor}>
          <Theme>
            <ErrorBoundary>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider>
                  <FilterBookingsProvider>
                    <OffsetPageProvider>
                      <TabsProvider>
                        <QueryClientProvider client={queryClient}>
                          <Hydrate state={pageProps.dehydratedState}>
                            <Component {...pageProps} />
                          </Hydrate>
                        </QueryClientProvider>
                      </TabsProvider>
                    </OffsetPageProvider>
                  </FilterBookingsProvider>
                  <Snackbar />
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </ErrorBoundary>
          </Theme>
        </PersistGate>
      </Provider>
    </LCProvider>
  );
};

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default App;
