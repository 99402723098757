import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import ETabs from 'enum/ETabs';

const TabsContext = createContext();

const initialState = ETabs.SHARING;

const TabsProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(initialState);

  return <TabsContext.Provider value={[activeTab, setActiveTab]}>{children}</TabsContext.Provider>;
};

TabsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { TabsProvider, TabsContext };
