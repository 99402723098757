import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from './CssBaseline'
import lightTheme from './themes/light'

const Theme = ({ children, theme }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
}
Theme.defaultProps = {
  theme: lightTheme,
}

export default Theme
