import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import newBase from './themes/newBase'
import { NewCssBaseLine as CssBaseline } from './CssBaseline'

const Theme = ({ children, theme }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object,
}
Theme.defaultProps = {
  theme: newBase,
}

export default Theme
