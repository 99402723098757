const colors = {}

/* eslint-disable camelcase */

// Blue colors
colors.blue_003 = '#0031FF'
colors.blue_000 = '#000380'
colors.blue_0c7 = '#0C72E8'
colors.blue_37c = '#37C0ED'
colors.blue_d5e = '#D5EEFA'

// Green colors
colors.green_34b = '#34BA92'
colors.green_3fe = '#3FE1B0'
colors.green_cee = '#CEE9DF'
colors.green_f0f = '#F0F8F5'

// Grey colors
colors.grey_38 = '#383838'
colors.grey_79 = '#797979'
colors.grey_a6 = '#A6A6A6'
colors.grey_e6 = '#E6E6E6'
colors.grey_f6 = '#F6F6F6'

// Red colors
colors.red_e82 = '#E82A16'
colors.red_cb1 = '#CB1F0D'
colors.red_eda = '#EDA89E'

// Orange colors
colors.orange_fa6 = '#FA673B'

// White color
colors.white = '#FFFFFF'

// Yellow colors
colors.yellow_f0b = '#F0B60F'

export default Object.freeze(colors)
