import i18n from 'i18next';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getI18n, initReactI18next } from 'react-i18next';
import locales from 'locales';

// import appConfig from './index'

const fallbackLng = 'pt-BR';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    defaultNS: 'translations',
    resources: locales,
    // debug: appConfig.mode !== 'PRODUCTION',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      useSuspense: false,
    },
  });

const { language } = getI18n();

if (Object.keys(i18n.store.data).includes(language)) {
  moment.locale(language);
} else {
  moment.locale(fallbackLng);
}

export default i18n;
